<template>
  <div class="content">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        class="items"
        @load="queryList"
      >
        <div v-for="item in list" :key="item.id" class="item">
          <div class="prod">
            <div class="logo-v">
              <img class="logo" :src="item.logo" alt="门店地址" />
            </div>
            <div class="card">
              <span class="title">{{ item.title }}</span>
              <span class="name">{{ item.name }}</span>
              <span class="phone">手机号 {{ item.phone }}</span>
              <span class="time">绑定时间 {{ item.create_time }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh } from 'vant'

export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      list: [],
      showAdd: false,
      info: {},
      queryParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    getDetails(item) {
      this.info = item
      this.showDetails = true
    },
    onRefresh() {
      this.finished = false
      this.queryList()
    },
    toAdd() {
      this.$router.push('/activityAdd')
    },
    toDetails(activity) {
      this.$state.activity = activity
      this.$router.push({ name: 'details' })
    },
    queryList() {
      this.showAdd = false
      this.loading = true
      this.$http({
        url: '/agent/merchantIndex',
        method: 'get',
        params: this.$http.adornParams(this.queryParams),
      })
        .then(({ data }) => {
          this.list = data.data
          this.loading = false
          if (data.last_page > this.queryParams.page) {
            this.page++
          } else {
            this.refreshing = false
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
          this.loading = false
          this.refreshing = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
}
.content {
  padding: 0 32px;
  height: 100vh;
  background: #fafafa;
  .line {
    border-bottom: 1px solid #f1f2f5;
    width: 100vw;
    margin: 0 -32px;
  }
  .items {
    padding: 8px 0;
    .item {
      border-radius: 16px 16px 16px 16px;
      background: #ffffff;
      padding: 16px;
      position: relative;
      margin-bottom: 10px;
      .prod {
        margin: 0 auto;
        display: flex;
        border-radius: 12px 12px 12px 12px;
        // padding-top: 5px;
        .logo-v {
          width: 50px;
          height: 50px;
          .logo {
            width: 50px;
            height: 50px;
            border-radius: 4px 4px 4px 4px;
          }
        }
        .money-info {
          // margin-right: 10px;
          position: absolute;
          line-height: 60px;
          right: 24px;
          .money {
            height: 60px;
            font-weight: bold;
            color: #242426;
            .symbol {
              font-size: 25px;
            }
            .number {
              font-size: 55px;
            }
          }

          .where {
            display: block;
            text-align: right;
            font-size: 10px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 12px;
            padding-right: 4px;
          }
        }
        .card {
          padding-top: 2px;
          padding-left: 14px;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            display: block;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .name {
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          }
          .phone {
            padding-top: 5px;
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
          }

          .time {
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
          }
        }
      }
      .remarks {
        height: 33px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        text {
          line-height: 33px;
        }
        .desc {
          position: absolute;
          left: 14px;
        }
        .use {
          position: absolute;
          right: 24px;
          width: 61px;
          height: 19px;
          line-height: 19px;
          background: #ffffff;
          color: #f56b4e;
          border-radius: 51px 51px 51px 51px;
          top: 7px;
          text-align: center;
        }
      }
      .tip {
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        padding: 5px 24px 10px 14px;
      }
    }
  }
}
</style>
